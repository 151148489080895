import { useEffect } from "react";
import HeroImage from "../images/hero-cover1.jpg";
import { LinkIcon } from "@heroicons/react/24/outline";
import Typography from "../components/Typography";

export function Hero(props) {
  const title = props.title;
  const subtitle = props.subtitle;
  const image = props.image;
  const description = props.description;
  const buttons = props.buttons;

  return (
    <>
      {/* <div
        id="top"
        className="bg-slate-800 absolute xl:bottom-0 top-0 min-h-screen w-screen flex flex-col grow justify-center items-center"
      > */}
      {/* bg-gradient-to-tr from-slate-800 to-slate-800 */}
      <div className="flex flex-col grow justify-center items-center bg-slate-900  h-full w-full absolute inset-x-0 bottom-0 xl:top-0 xl:h-full">
        <div className="h-full w-full xl:grid xl:grid-cols-1">
          <img
            className="object-cover opacity-10 object-top w-full h-full xl:absolute xl:inset-0 top:0 left-0 right-0 bottom-0"
            src={HeroImage}
            alt=""
          />
          <div className="absolute inset-0 h-full w-full" />
        </div>
      </div>
      <div
        id="content-grid"
        className="min-h-screen h-full flex grow-1 justify-center items-center content-center"
      >
        <div
          id="main-hero"
          className="flex flex-col my-auto justify-center text-center relative px-4  lg:px-8 min-h-screen"
        >
          <h1 className="font-light text-4xl sm:text-5xl md:text-6xl lg:text-7xl max-w-6xl text-center tracking-widest mx-auto text-white  mb-5">
            {/* <Typography variant="h1"></Typography> */}
            {title}
          </h1>
          <h2 className="h2 font-normal text-xl md:text-3xl max-w-4xl text-center tracking-normal mx-auto text-white  mb-5">
            {subtitle}
          </h2>
          {description && description != "" && (
            <p className="text-center text-xl tracking-normal text-white max-w-3xl mx-auto mt-4">
              {description}
            </p>
          )}
          <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex justify-center">
            {/* <div className="flex justify-center space-y-4 sm:space-y-0 m-10 sm:inline-grid sm:grid-cols-1 sm:gap-5">
                <a
                  href="https://app.breachlink.com"
                  className="bg-opacity-90 w-full  flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-500 hover:bg-indigo-600 sm:px-8"
                >
                  Login
                </a>
              </div> */}
            <div className="flex justify-center space-y-4 sm:space-y-0 m-10 sm:inline-grid sm:grid-cols-1 sm:gap-5">
              <a
                href="https://app.breachlink.com/signup"
                onClick={(e) => {
                  e.stopPropagation();
                  props.executeScroll("vetBanner");
                }}
                className="bg-opacity-90 w-full  flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium text-slate-900 rounded-md shadow-sm  bg-brandGreen-500 hover:bg-brandGreen-600 sm:px-8"
              >
                Join Breachlink for Free
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
