import React from "react";
import { Icon } from "@iconify/react";

// const steps = [
//   {
//     name: "Create your request",
//     description:
//       "This needs to be a description that is long enough to wrap three lines of text and not show anything after it gets there.",
//     status: "complete",
//   },
//   {
//     name: "Receive bids",
//     description:
//       "This needs to be a description that is long enough to wrap three lines of text and not show anything after it gets there.",
//     status: "complete",
//   },
//   {
//     name: "Select a vendor",
//     description:
//       "This needs to be a description that is long enough to wrap three lines of text and not show anything after it gets there.",
//     status: "complete",
//   },
// ];

export default function Steppers({ steps, type }) {
  return (
    <div className="relative w-full flex flex-col items-center ">
      {/* Limited Width Line */}

      {/* Step Circles with Spacing */}
      <div className="relative flex justify-between ">
        {steps && (
          <div className="mx-auto mt-8 max-w-2xl  lg:max-w-none">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-2 lg:max-w-none lg:grid-cols-3">
              {steps.map((feature, idx) => (
                <div
                  key={feature.name}
                  className="flex flex-col lg:items-center py-6"
                >
                  <dt className="text-left lg:text-center flex items-center gap-x-3  text-xl font-semibold leading-7 text-slate-800">
                    {/* {idx != 2 && (
                      <div
                        id="connecting"
                        className="absolute w-full h-0.5 bg-blue-500"
                      ></div>
                    )} */}
                    <div
                      id="circleNumbers"
                      className={`${
                        type == "icons" ? "hidden" : "flex items-center"
                      } w-10 h-10 rounded-full justify-center font-bold ${
                        feature.status === "complete"
                          ? "bg-blue-600 text-white"
                          : feature.status === "current"
                          ? "border-2 border-blue-600 bg-white text-blue-600"
                          : "border-2 border-gray-300 bg-white text-gray-600"
                      }`}
                    >
                      {idx + 1}
                    </div>
                    <div
                      id="circleIcons"
                      className={`${
                        type == "numbered" ? "hidden" : "flex items-center"
                      } w-10 h-10 rounded-full  justify-center font-bold text-secondary-500`}
                    >
                      <Icon
                        icon={feature.icon}
                        className="h-12 w-12 flex-none"
                        aria-hidden="true"
                      />
                    </div>
                  </dt>
                  <dt className="text-left lg:text-center py-4 font-semibold text-slate-800 text-xl">
                    {feature.name}
                  </dt>
                  <dt className="text-left lg:text-center py-4 ">
                    {feature.description}
                  </dt>
                </div>
              ))}
            </dl>
          </div>
        )}
      </div>
    </div>
  );
}
