import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  LockClosedIcon,
  FlagIcon,
  ShoppingCartIcon,
} from "@heroicons/react/20/solid";
import { Paragraph } from "../components/Paragraph";
import { HeadingTwo } from "../components/HeadingTwo";
import { Icon } from "@iconify/react";
import { getThemeStyles } from "../modules/_themeStyles";
import SectionDivider from "./SectionDivider";

export default function ThreeColumn({
  title,
  description,
  variant,
  bgColor,
  features,
}) {
  const {
    backgroundColor,
    gridCols,
    titleTextColor,
    headerTextColor,
    descriptionTextColor,
    featureHeaderTextColor,
    featureDescriptionTextColor,
  } = getThemeStyles(variant, bgColor);
  return (
    <div className={`${backgroundColor} py-6`}>
      <SectionDivider
        title={title}
        description={description}
        variant={variant}
        bgColor={bgColor}
      />
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        {/* <div className="mx-auto max-w-4xl text-center">
          <p
            className={`mt-2 text-3xl  font-bold tracking-tight ${headerTextColor} sm:text-4xl`}
          >
            {title}
          </p>
          <p
            className={`mt-6 text-lg leading-8 ${descriptionTextColor} max-w-none`}
          >
            {description}
          </p>
        </div> */}
        {features && (
          <div className="mx-auto mt-8 max-w-2xl  lg:max-w-none">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-2 lg:max-w-none lg:grid-cols-3">
              {features.map((feature) => (
                <div
                  key={feature.name}
                  className="flex flex-col lg:items-center py-6"
                >
                  <dt className="text-left lg:text-center flex items-center gap-x-3  text-xl font-semibold leading-7 text-slate-800">
                    <Icon
                      icon={feature.icon}
                      className="text-left lg:text-center s h-24 w-24 flex-none text-indigo-400"
                      aria-hidden="true"
                    />
                  </dt>
                  <dt className="text-left lg:text-center py-4 font-semibold text-slate-800 text-xl">
                    {feature.name}
                  </dt>
                  <dt className="text-left lg:text-center py-4 ">
                    {feature.description}
                  </dt>
                </div>
              ))}
            </dl>
          </div>
        )}
        {/* <div className="mx-auto max-w-2xl lg:text-center pt-10 mt-10">
          <HeadingTwo variant={"dark"}>
            We suggest a nap. We love naps!
          </HeadingTwo>
        </div> */}
      </div>
    </div>
  );
}
