import React from "react";
import { hubsInterface } from "../modules/_hubs";
import { getThemeStyles } from "../modules/_themeStyles";
export default function HubsForm({ variant, bgColor, refId }) {
  const [showThankYouMessage, setShowThankYouMessage] = React.useState(false);
  const [thankYouMessage, setThankYouMessage] = React.useState("");

  const handleSubmit = async (event) => {
    let formId = "511e6835-e65d-45b6-8f1e-fa90e2af7ced";
    event.preventDefault();

    console.log(event.target.email.value);
    let response;
    if (refId) {
      response = await hubsInterface.submitFormDynamic(
        event.target.email.value,
        formId,
        "NetD Contact Form"
      );
    } else {
      response = await hubsInterface.submitForm(
        event.target.email.value,
        formId,
        "Client"
      );
    }

    setShowThankYouMessage(true);
    setThankYouMessage("Thanks for reaching out.  We'll be in touch soon!");
  };
  const {
    backgroundColor,
    gridCols,
    titleTextColor,
    headerTextColor,
    descriptionTextColor,
    featureHeaderTextColor,
    featureDescriptionTextColor,
  } = getThemeStyles(variant, bgColor);

  return (
    <div className={`${backgroundColor}`}>
      <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="px-6 py-6 xl:flex xl:items-center">
          <div className="xl:w-0 xl:flex-1">
            <h2
              className={`text-2xl font-extrabold tracking-tight ${descriptionTextColor} sm:text-3xl`}
            >
              Contact us!
            </h2>
            <p
              className={`mt-3 max-w-3xl text-lg leading-6 ${featureDescriptionTextColor}`}
            >
              {showThankYouMessage ? (
                thankYouMessage
              ) : (
                <span>
                  Have questions or want to learn more about Breachlink? Provide
                  your email and we'll be in touch soon!
                </span>
              )}
            </p>
          </div>
          {!showThankYouMessage && (
            <>
              <div className="mt-8 sm:w-full sm:max-w-md xl:mt-0 xl:ml-8">
                <form className="sm:flex" onSubmit={handleSubmit}>
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="email"
                    name="emails"
                    type="email"
                    autoComplete="email"
                    required
                    className="w-full border-gray-200 px-5 py-3 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-purple-700 focus:ring-white rounded-md"
                    placeholder="Enter your email"
                  />
                  <button
                    type="submit"
                    // onClick={handleSubmit}
                    className="mt-3 w-full flex items-center justify-center px-5 py-3 border border-transparent shadow text-base font-medium rounded-md text-white bg-brandGreen-500 hover:bg-brandGreen-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-purple-700 focus:ring-white sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0"
                  >
                    Email me
                  </button>
                </form>

                {/* <p className="mt-3 text-sm text-purple-200">
                  We care about the protection of your data. Read our{" "}
                  <a href="#" className="text-white font-medium underline">
                    Privacy Policy.
                  </a>
                </p> */}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
