/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import jwtDecode from "jwt-decode";
import Fade from "react-reveal/Fade";
import BreachlinkLogo from "../images/BreachConnectLogoBlue.png";
import NetDLogo from "../images/logos/netD.svg";
import Logo from "../images/dsulogo.jpg";
import HeroImage from "../images/hero-cover1.jpg";
import cmapScreenshot1 from "../images/screenshot-breachlink-templates.png";
import cmapScreenshot2 from "../images/Screenshot-Compare.png";
import cmapScreenshot3 from "../images/screenshot-breachlink-comments.png";
import cmapScreenshot4 from "../images/screenshot-breachlink-marketplace.png";

//vendor logos
import epiq from "../images/logos/vendors/epiq.png";
import kroll from "../images/logos/vendors/kroll.png";
import integreon from "../images/logos/vendors/newIntegreon.png";
import consilio from "../images/logos/vendors/consilio.png";

import { ChevronDownIcon } from "@heroicons/react/solid";
import { Hero } from "../parts/Hero";
import { FormBanner } from "../parts/FormBanner";
import HubsForm from "../components/Hubsform";
import { SmallBanner } from "../parts/SmallBanner";
import { Header } from "../parts/Header";
import { ThreePartFeature } from "../parts/ThreePartFeature";
import FeatureGrid from "../parts/FeatureGrid";
import CenterBrandedCTA from "../parts/CenterBrandedCTA";
import TeamSmallPictures from "../parts/TeamSmallPictures";
import StatsLarge from "../parts/StatsLargeDark";
import FeatureList from "../parts/FeatureList";
import CTASplitWithImage from "../parts/CTASplitWithImage";
import SinglePriceWithDetails from "../parts/SinglePriceWithDetails";
import PartnerForm from "../parts/FormBannerPartner";
import ModalBasic from "../components/ModalBasic";
import NotificationBanner from "../components/NotificationBanner";
import PricingModule from "../parts/PricingModule";
import { _pricing } from "../modules/pricing";

import ThreeColumnOnDark from "../parts/ThreeColumnOnDark";
import ThreeColumn from "../parts/ThreeColumn";
import LogoCloudWithCTA from "../parts/LogoCloudWithCTA";
import BenefitsWithBackground from "../parts/BenefitsWithBackground";
import SecondaryFeatures from "../parts/SecondaryFeatures";
import TestimonialsGrid2 from "../parts/TestimonialsGrid2";
import Team from "../parts/Team";
import SectionDivider from "../parts/SectionDivider";
import DevNote from "../components/DevNote";
import { _auth } from "../modules/_auth";
import TestimonialBanner from "../parts/TestimonialBanner";
import { HeroHalf } from "../parts/HeroHalf";
import FadedImageBackground from "../parts/FadedImageBackground";
import ContentGrid from "../parts/ContentGrid";
import Steppers from "../components/Steppers";
import Stepper from "../components/Stepper";
// import { exec } from "child_process";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NetDLanding() {
  const history = useHistory();
  const location = useLocation();
  const { ref, token } = useParams();
  //set profile role to "user"
  _auth.setUserProfile({ role: "user" });
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [referrerName, setReferrerName] = useState("");
  const [refId, setRefId] = useState("");
  const [profile, setProfile] = useState({});
  const pricing = _pricing.getPricing();
  const setModalOpen = () => {
    setShowModal(false);
  };

  useEffect(() => {
    let references = [
      { id: "142ac1d7c84b447595aab4ee", name: "NetDiligence" },
      { id: "142ac1d7c84b447595aab4ef", name: "Your vendor" },
    ];
    if (!ref || !references.find((r) => r.id === ref)) {
      history.push("/");
    } else {
      setReferrerName(references.find((r) => r.id === ref).name);
      setRefId(ref);
      let decoded;

      let currentTime;
      let expired;
      if (token && token !== "undefined" && token !== null) {
        decoded = jwtDecode(token);
        console.log("decoded", decoded);
        currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
          expired = true;
        }
      } else {
        expired = true;
        decoded = { email: "", name: "", first_name: "", last_name: "" };
      }

      if (expired) {
        console.log("expired");
        setProfile(null);
      } else {
        console.log("not expired");
        console.log("using Profile", decoded);
        setProfile(decoded);
      }
    }
  }, [ref, history]);

  const handleBannerClose = (e) => {
    // e.preventDefault();
    // e.stopPropagation();
    setOpen(false);
  };
  // const hubsForm = useRef();
  const executeScroll = (e) => {
    // alert("scrolling to " + e);
    console.log("scrolling to ", e);
    const element = document.getElementById(e);
    element.scrollIntoView({
      alignToTop: true,
      behavior: "smooth",
      // block: "nearest",
      inline: "start",
    });
  };

  return (
    <div className=" bg-white ">
      {profile && (
        <div className="z-50  absolute top-0 left-0  bg-brandGreen-500  p-2 w-full lg:w-[50%] xl:w-full ">
          <div className="pb-2">
            <div className="text-xl font-semibold">TEST ONLY</div>
          </div>
          <div className="flex items-center  ">
            {profile && profile.name} {" " + profile && profile.email}{" "}
            {profile && profile.company}
          </div>
        </div>
      )}
      {open && (
        <NotificationBanner
          className={"sticky top-10 z-100"}
          setOpen={(e) => handleBannerClose(e)}
          showClose={true}
          text={"Ask us about our services!"}
        />
      )}
      <main className="flex flex-col">
        {/* <div id="top">
          <HeroHalf
            // title="Empowering Legal with Simplicity "
            title="Helping insurers combat the rising cost of data breach notification."
            subtitle="Breachlink helps insurers combat the rising cost of data breach notification."
            // subtitle="Create bid requests in seconds. Get bids in minutes. Compare them effortlessly."
            image={HeroImage}
            // description="We're enabling Data Breach and eDiscovery by connecting law firms, insurers, and corporations with world-class service providers."
            buttons=""
            executeScroll={executeScroll}
            setShowModal={setShowModal}
          />
        </div> */}
        <Fade id="section-hero">
          <div id="hero">
            {" "}
            <FadedImageBackground
              id={"insurance-benefits"}
              title={"Insurance Carriers"}
              header={"Enhance Efficiency and Transparency in Bid Management."}
              orientation="normal"
              description={
                "Breachlink helps insurers oversee the bid process with greater efficiency and transparency. By ensuring attorneys use Breachlink, you gain real-time insights into bids and can make more informed decisions faster."
              }
              data={[
                {
                  name: "Real-Time Monitoring",
                  description:
                    "With Breachlink Connect, view bids across law firms as they come in, saving time and enhancing decision-making.",
                },
                {
                  name: "Cost Efficiency",
                  description:
                    "Streamline the process to reduce administrative overhead and focus on selecting the best vendors.",
                },
                {
                  name: "Improved Oversight",
                  description:
                    "Gain transparency into the bidding process, ensuring compliance and quality.",
                },
                {
                  name: "Collaborate Seamlessly",
                  description:
                    "Collaborate in real-time with your panel counsel to select the best vendors.",
                },
              ]}
              layout={"grid"}
              bgColor={"slate-800"}
              backgroundColor={"bg-gray"}
              gradient={false}
            >
              {" "}
              <ContentGrid
                title="Regain control. Maintain control."
                subtitle={`${referrerName} has partnered with Breachlink to help the cyber insurance industry combat the rising cost and complexity of managing data breach notification.`}
                // subtitle="Create bid requests in seconds. Get bids in minutes. Compare them effortlessly."
                image={HeroImage}
                // description={
                //   "Breachlink connects you with top service providers, ensuring cost-effective and efficient management of data breaches. Say goodbye to manual tracking and hello to optimized results."
                // }
                // description="We're enabling Data Breach and eDiscovery by connecting law firms, insurers, and corporations with world-class service providers."
                buttons=""
                executeScroll={executeScroll}
                setShowModal={setShowModal}
                profile={profile ? profile : null}
              />
            </FadedImageBackground>
          </div>
        </Fade>
        <Fade id="section-legal-benefits">
          <div id="overview">
            {" "}
            <Fade id="breachlinkForEveryone">
              <ThreeColumn
                title={"Breachlink for the Cyber Insurance Industry"}
                description={
                  "Breachlink revolutionizes data breach procurement for the cyber insurance industry. Our platform streamlines bid management and vendor selection, optimizing costs, enhancing quality, and saving time. Gain greater efficiency and transparency in overseeing your bid processes with Breachlink."
                }
                variant={"light"}
                features={[
                  {
                    name: "Optimize Cost",
                    description:
                      "Optimize your spending by encouraging competitive bidding among your existing vendors.",
                    href: "#",
                    icon: "streamline:graph-bar-decrease",
                  },
                  {
                    name: "Maximize Efficiency",
                    description:
                      "Streamline the process to reduce administrative overhead and focus on selecting the best vendors.",
                    href: "#",
                    icon: "fa:gears",
                  },
                  {
                    name: "Improve Flexibility",
                    description:
                      "Choose the best vendor for each project, ensuring you always have the right partner.",
                    href: "#",
                    icon: "mynaui:users-group",
                  },
                ]}
              />
            </Fade>
          </div>
        </Fade>
        <Fade id="small-banner-stop-digging">
          <div className={`  bg-slate-800 py-12`}>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-7xl text-center">
                <p
                  className={`mt-2 text-4xl  font-semibold tracking-normal text-white`}
                >
                  <div className=" text-opacity-80 ">
                    Stop digging through emails, making spreadsheets, wasting
                    time, and losing money.
                  </div>

                  <div className="text-brandGreen-500">
                    Let Breachlink do the work.
                  </div>
                </p>
              </div>
            </div>
          </div>
          {/* <SmallBanner description="Create bid requests in seconds. Get bids in minutes. Compare them effortlessly." /> */}
        </Fade>
        <Fade id="three-ways">
          <div className="w-full bg-gray-100 px-12 py-12">
            <SectionDivider
              title={"Three ways to streamline your bid management process"}
              description={
                "We provide three effortless ways to get the job done, and no matter which one you choose, you have complete real-time transparency and control over the process."
              }
              variant={"light"}
              bgColor={"gray-100"}
            />
            <Steppers
              type="icons"
              steps={[
                {
                  name: "Let us do it for you",
                  description:
                    "Our no-cost service gives you the best of both worlds.  We manage the process and communications with the vendors; you select the winner and negotiate the contract.",
                  status: "complete",
                  icon: "healthicons:group-discussion-meetingx3",
                },
                {
                  name: "Require your law firms use Breachlink",
                  description:
                    "Breachlink is used by Law Firms around the world.  Requiring your law firms to use Breachlink not only ensures you have real-time transparency and control, but could significantly reduce your cost to receive pricing.",
                  status: "complete",
                  icon: "mdi:briefcase-arrow-left-right",
                },
                {
                  name: "Do it yourself",
                  description:
                    "Managing the quote-request process is easy with Breachlink.  Create a request, invite vendors, and manage the process from start to finish.  You can save time and money, maintain control, and ensure things are done exactly as you want.",
                  status: "complete",
                  icon: "fluent:handshake-20-filled",
                },
              ]}
            />
          </div>
        </Fade>

        <Fade id="small-banner-create-in-seconds">
          <div className={`  bg-slate-800 py-12`}>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-7xl text-center">
                <p
                  className={`mt-2 text-4xl  font-semibold tracking-tight text-white`}
                >
                  <div className=" text-opacity-80 ">
                    {" "}
                    Create bid requests in seconds. Get bids in minutes.{" "}
                  </div>

                  <div className="text-brandGreen-500">
                    Compare them effortlessly.
                  </div>
                </p>
              </div>
            </div>
          </div>
          {/* <SmallBanner description="Create bid requests in seconds. Get bids in minutes. Compare them effortlessly." /> */}
        </Fade>
        <Fade id="section-features">
          <div className="w-full bg-gray-100 px-12 py-12">
            <SectionDivider
              title={"Easy as 1, 2, 3"}
              description={
                "Breachlink makes the bid management process simple enough that anyone can do it."
              }
              variant={"light"}
              bgColor={"gray-100"}
            />
            <Steppers
              type="numbered"
              steps={[
                {
                  name: "Create your request",
                  description:
                    "Create a new request in minutes following our request creation wizard and invite vendors to bid.",
                  status: "complete",
                },
                {
                  name: "Receive bids",
                  description:
                    "Get notified when new bids come in. Use our AI tools to compare apples-to-apples and ask questions.",
                  status: "complete",
                },
                {
                  name: "Select a vendor",
                  description:
                    "Choose the vendor that meets your needs directly in the Breachlink app.",
                  status: "complete",
                },
              ]}
            />
          </div>
        </Fade>
        <Fade id="logoCloud">
          <div className="relative">
            <LogoCloudWithCTA
              title="
                World Class Service Providers"
              description="Our marketplace contains over 50 world-class vendors spanning the incident response process, including data mining, review, notification, credit monitoring, and call-center services."
              showCTA={false}
              ctaTitle={
                "Our marketplace currently has over 50 world-class service providers and gorowing every day."
              }
              ctaText={"Check out the full marketplace"}
              logos={[
                {
                  src: epiq,
                  alt: "Epiq",
                  width: 158,
                  height: 48,
                },
                {
                  src: kroll,
                  alt: "Kroll",
                  width: 158,
                  height: 48,
                },
                {
                  src: integreon,
                  alt: "Integreon",
                  // width: 158,
                  // height: 48,
                },
                {
                  src: consilio,
                  alt: "Consilio",
                  width: 158,
                  height: 48,
                },
                // {
                //   src: "https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg",
                //   alt: "Statamic",
                //   width: 158,
                //   height: 48,
                // },
              ]}
              bgColor={"bg-white"}
            />
          </div>
        </Fade>
        {/* <Fade id="testimonial-banner">
          <TestimonialBanner />
        </Fade> */}

        <div id="solutions"> </div>

        <Fade id="section-split-image-cta">
          <CTASplitWithImage
            title=""
            header="Ready to try it out?"
            description="Get started today."
            ctaText="Sign up for free"
            executeScroll={executeScroll}
          />
        </Fade>

        {/* <Fade id="cta-ready-next-step">
          {" "}
          <CenterBrandedCTA
            text={"Ready to take the next step?"}
            buttonText={"Sign up for free"}
            link={"https://app.breachlink.com/signup"}
            executeScroll={executeScroll}
            bottomText={""}
            variant="dark" //light, brand, dark
          />
        </Fade> */}

        <Fade id="section-hubspot-section-1">
          <div id="contact" className=" lg:pt-0">
            <HubsForm id="insurerContactForm" variant={"light"} refId={refId} />
          </div>
        </Fade>
      </main>
      <ModalBasic
        id="partner_modal"
        modalOpen={showModal}
        setModalOpen={setModalOpen}
        title="Get in touch!"
      >
        <PartnerForm id="partnerForm" />
      </ModalBasic>
    </div>
  );
}
